.w-modal{
    min-width: 35vw;
    min-height: 150px;
    border-radius: 16px;
    display:flex;
    flex-direction: column;
    background: #00000077 0% 0% no-repeat padding-box;
    backdrop-filter: blur(12px);
     -webkit-backdrop-filter: blur(12px);  
     -moz-backdrop-filter: blur(12px); 
  }
  
  .w-modal-message{
    min-width: 40vw;
    min-height: 250px;
    /* height: 50vh; */
    border-radius: 40px;
    display:flex;
    flex-direction: column;
    background: #00000077 0% 0% no-repeat padding-box;
    backdrop-filter: blur(28px);
     -webkit-backdrop-filter: blur(28px);  
     -moz-backdrop-filter: blur(28px); 
    opacity: 1;
    max-width: 90vw;
  }

  .w-modal-body{
    padding:1rem 3rem 3rem 3rem
  }

  .w-modal-header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .w-modal-close{
    margin: 8px 15px 0 0;    
  }
  
  /* Smartphone o piccoli Tablet */
  @media all and (max-width: 480px) {
    .w-modal{
      margin-left: 3vw;
      margin-right: 3vw;
      border-radius: 16px;
      display:flex;
      flex-direction: column;
      /* height: 88vh; */
      width: 100vw;
    }
  }