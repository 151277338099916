.videoIframe {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.videoIframe iframe {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    max-width: 90%;

}