* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Alphakind';
  font-style: normal;
  font-weight: 400;
  src: local('Alphakind'), url('css/font/Alphakind-gxyM5.ttf') format('woff');
}


.font-alphakind {
  font-family: 'Alphakind', sans-serif;
}

body {
  background: rgb(26, 19, 143);
  font-family: 'Alphakind', sans-serif;
  margin: 0;
  padding: 0;
}

.debug {
  border: 3px solid red !important;
}

.debuggy {
  border: 3px solid yellow !important;
}

.container-coming-soon {
  max-height: 100vh;
  height: 100vh;
  /* width: 100vw; */
  background-image: url(./img/bg-lands.jpg);
  justify-content: start;
  background-size: cover;
  overflow-y: auto;

}

.address {
  margin-top: 0 !important;
  font-size: 18px;
  /* font-weight: unset !important; */
}

.inner-container {
  width: 95vw;
  margin: auto;
  height: 100%;
}

.glass-card {
  background: rgba(255, 255, 255, 0.21);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.4px);
  -webkit-backdrop-filter: blur(6.4px);
  border: 1px solid rgba(255, 255, 255, 0.32);
  color: #fff;
  padding: 20px;
  top: 20%
}

.font-size {
  font-size: larger;
}

.glass-app {
  max-width: 60%;
  left: 10%;
}

/* .box {
  width: 35%;
  position: fixed;
  padding: 20px;
  color: #fff;
  font-size: xx-large;
  top: 33%;
  left: 10%;
  height: 340px;
} */

a {
  color: #00e1ff;
}

a:visited {
  color: #00e1ff;
}

/* .container-coming-soon h1 {position: absolute;} */
.container-coming-soon h1:after {
  content: url(https://thenemesis.io/wp-content/uploads/2022/10/1666193077474_587093.svg);
  position: absolute;
  left: 0px;
  bottom: -15px;
  width: 100px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

h2,h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

p {
  font-size: smaller;
  top: 150px;
}

.button {
  color: #FFFFFF !important;
  font-family: Alphakind;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  background-color: #1077ef;
  text-decoration: none;
  font-size: initial;
  padding: 20px 40px;
  border-radius: 50px;
  border: 2px solid #fff;
  top: 250px;
}

.button-search {
  color: #FFFFFF !important;
  font-family: Alphakind;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  background-color: #1077ef;
  text-decoration: none;
  font-size: initial;
  padding: 10px !important;
  border-radius: 50px;
  border: 2px solid #fff;
  top: 250px;
}

.input-token-id {
  color: #1077ef !important;
  font-family: Alphakind;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  background-color: #dddddd;
  text-decoration: none;
  font-size: initial;
  padding: 10px;
  border-radius: 50px;
  border: 2px solid #fff;
  top: 250px;
}

.text-white {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-inherit {
  cursor: inherit;
}

.position-absolute {
  position: absolute
}

.position-relative {
  position: relative
}

.d-flex {
  display: flex
}

.d-block {
  display: block
}

.flex-column {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-between {
  justify-content: space-between
}

.justify-content-center {
  justify-content: center
}

.justify-content-around {
  justify-content: space-around
}

.align-items-center {
  align-items: center;
}

.h-100 {
  height: 100%
}

.w-100 {
  width: 100%
}

.text-center {
  text-align: center
}

.text-left {
  text-align: left
}

.text-right {
  text-align: right
}

.overflow-y {
  overflow-y: auto;
}

.m-0 {
  margin: 0;
}


.ms-1 {
  margin-left: 2rem;
}

.mr-1 {
  margin-right: 2rem;
}

.mt-1 {
  margin-top: 2rem;
}

.mt-2 {
  margin-top: 2.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.p-20 {
  padding: 20px;
}

.pt-1 {
  padding-top: 0.5rem;
}

.ps-20 {
  padding-left: 20px;
}

.title {
  position: absolute;
}

.title:after {
  content: url(https://thenemesis.io/wp-content/uploads/2022/10/1666193077474_587093.svg);
  position: absolute;
  left: 0px;
  bottom: -15px;
  width: 100px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.text-danger{
  color: red;
}

@media only screen and (max-width: 600px) {
  body {
    background-position: top;
  }

  /* #box {
    width: 80%;
    top: 20%;
    left: 5%;
  } */

  h1 {
    font-size: 1.4em;
  }

  h2 {
    font-size: 1.1em;
  }

  h1:after {
    left: 0px;
    bottom: -15px;
    width: 100px;
  }

  p {
    top: 150px;
  }

  .button {
    top: 280px;
    width: 100%;
    text-align: center;
    margin-top: 25px
  }
}

.button-right {
  margin-left: 0;
}

.logo-nems{
  height: 20px;
  margin-right: 10px;
}


@media only screen and (min-width: 576px) {
  .button-right {
    margin-left: 25px;
  }

  .font-size {
    font-size: x-large;
  }
}

@media only screen and (min-width: 768px) {
  .inner-container {
    width: 75vw;
  }

}

@media only screen and (min-width: 1124px) {}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1800px) {
  .font-size {
    font-size: xx-large;
  }

}