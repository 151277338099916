.title-countdown {
    color: white;
    font-family: var(--fontSecondary);
    font-weight: 700;
    font-size: 3.8rem;
    letter-spacing: 5px;
    margin: 0;
    padding: 0;
}



@media all and (min-width: 481px) and (max-width: 736px) {

    .title-countdown {
        font-size: 2rem;
        line-height: 25px;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
    }

}

@media all and (max-width: 480px) {

    .title-countdown {
        font-size: 2rem;
        line-height: 25px;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
    }

}