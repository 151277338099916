.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

[class*="col-"] {
    flex: 0 0 auto;
}


.col, 
.col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-xs-1  {flex-basis: calc((100% / 12) *  1); max-width: calc((100% / 12) *  1);}
.col-xs-2  {flex-basis: calc((100% / 12) *  2); max-width: calc((100% / 12) *  2);}
.col-xs-3  {flex-basis: calc((100% / 12) *  3); max-width: calc((100% / 12) *  3);}
.col-xs-4  {flex-basis: calc((100% / 12) *  4); max-width: calc((100% / 12) *  4);}
.col-xs-5  {flex-basis: calc((100% / 12) *  5); max-width: calc((100% / 12) *  5);}
.col-xs-6  {flex-basis: calc((100% / 12) *  6) !important; max-width: calc((100% / 12) *  6) !important ;}
.col-xs-7  {flex-basis: calc((100% / 12) *  7); max-width: calc((100% / 12) *  7);}
.col-xs-8  {flex-basis: calc((100% / 12) *  8); max-width: calc((100% / 12) *  8);}
.col-xs-9  {flex-basis: calc((100% / 12) *  9); max-width: calc((100% / 12) *  9);}
.col-xs-10 {flex-basis: calc((100% / 12) * 10); max-width: calc((100% / 12) * 10);}
.col-xs-11 {flex-basis: calc((100% / 12) * 11); max-width: calc((100% / 12) * 11);}
.col-xs-12 {flex-basis: calc((100% / 12) * 12); max-width: calc((100% / 12) * 12);}


@media only screen and (min-width: 576px) {

      .col-sm {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
      }

      .col-sm-1  {flex-basis: calc((100% / 12) *  1); max-width: calc((100% / 12) *  1);}
      .col-sm-2  {flex-basis: calc((100% / 12) *  2); max-width: calc((100% / 12) *  2);}
      .col-sm-3  {flex-basis: calc((100% / 12) *  3); max-width: calc((100% / 12) *  3);}
      .col-sm-4  {flex-basis: calc((100% / 12) *  4); max-width: calc((100% / 12) *  4);}
      .col-sm-5  {flex-basis: calc((100% / 12) *  5); max-width: calc((100% / 12) *  5);}
      .col-sm-6  {flex-basis: calc((100% / 12) *  6); max-width: calc((100% / 12) *  6);}
      .col-sm-7  {flex-basis: calc((100% / 12) *  7); max-width: calc((100% / 12) *  7);}
      .col-sm-8  {flex-basis: calc((100% / 12) *  8); max-width: calc((100% / 12) *  8);}
      .col-sm-9  {flex-basis: calc((100% / 12) *  9); max-width: calc((100% / 12) *  9);}
      .col-sm-10 {flex-basis: calc((100% / 12) * 10); max-width: calc((100% / 12) * 10);}
      .col-sm-11 {flex-basis: calc((100% / 12) * 11); max-width: calc((100% / 12) * 11);}
      .col-sm-12 {flex-basis: calc((100% / 12) * 12); max-width: calc((100% / 12) * 12);}

}
@media only screen and (min-width: 768px) {

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1  {flex-basis: calc((100% / 12) *  1); max-width: calc((100% / 12) *  1);}
  .col-md-2  {flex-basis: calc((100% / 12) *  2); max-width: calc((100% / 12) *  2);}
  .col-md-3  {flex-basis: calc((100% / 12) *  3); max-width: calc((100% / 12) *  3);}
  .col-md-4  {flex-basis: calc((100% / 12) *  4); max-width: calc((100% / 12) *  4);}
  .col-md-5  {flex-basis: calc((100% / 12) *  5); max-width: calc((100% / 12) *  5);}
  .col-md-6  {flex-basis: calc((100% / 12) *  6); max-width: calc((100% / 12) *  6);}
  .col-md-7  {flex-basis: calc((100% / 12) *  7); max-width: calc((100% / 12) *  7);}
  .col-md-8  {flex-basis: calc((100% / 12) *  8); max-width: calc((100% / 12) *  8);}
  .col-md-9  {flex-basis: calc((100% / 12) *  9); max-width: calc((100% / 12) *  9);}
  .col-md-10 {flex-basis: calc((100% / 12) * 10); max-width: calc((100% / 12) * 10);}
  .col-md-11 {flex-basis: calc((100% / 12) * 11); max-width: calc((100% / 12) * 11);}
  .col-md-12 {flex-basis: calc((100% / 12) * 12); max-width: calc((100% / 12) * 12);}

}
@media only screen and (min-width: 1124px) {

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1  {flex-basis: calc((100% / 12) *  1); max-width: calc((100% / 12) *  1);}
  .col-lg-2  {flex-basis: calc((100% / 12) *  2); max-width: calc((100% / 12) *  2);}
  .col-lg-3  {flex-basis: calc((100% / 12) *  3); max-width: calc((100% / 12) *  3);}
  .col-lg-4  {flex-basis: calc((100% / 12) *  4); max-width: calc((100% / 12) *  4);}
  .col-lg-5  {flex-basis: calc((100% / 12) *  5); max-width: calc((100% / 12) *  5);}
  .col-lg-6  {flex-basis: calc((100% / 12) *  6); max-width: calc((100% / 12) *  6);}
  .col-lg-7  {flex-basis: calc((100% / 12) *  7); max-width: calc((100% / 12) *  7);}
  .col-lg-8  {flex-basis: calc((100% / 12) *  8); max-width: calc((100% / 12) *  8);}
  .col-lg-9  {flex-basis: calc((100% / 12) *  9); max-width: calc((100% / 12) *  9);}
  .col-lg-10 {flex-basis: calc((100% / 12) * 10); max-width: calc((100% / 12) * 10);}
  .col-lg-11 {flex-basis: calc((100% / 12) * 11); max-width: calc((100% / 12) * 11);}
  .col-lg-12 {flex-basis: calc((100% / 12) * 12); max-width: calc((100% / 12) * 12);}


}

@media only screen and (min-width: 1280px) {

  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-1  {flex-basis: calc((100% / 12) *  1); max-width: calc((100% / 12) *  1);}
  .col-xl-2  {flex-basis: calc((100% / 12) *  2); max-width: calc((100% / 12) *  2);}
  .col-xl-3  {flex-basis: calc((100% / 12) *  3); max-width: calc((100% / 12) *  3);}
  .col-xl-4  {flex-basis: calc((100% / 12) *  4); max-width: calc((100% / 12) *  4);}
  .col-xl-5  {flex-basis: calc((100% / 12) *  5); max-width: calc((100% / 12) *  5);}
  .col-xl-6  {flex-basis: calc((100% / 12) *  6); max-width: calc((100% / 12) *  6);}
  .col-xl-7  {flex-basis: calc((100% / 12) *  7); max-width: calc((100% / 12) *  7);}
  .col-xl-8  {flex-basis: calc((100% / 12) *  8); max-width: calc((100% / 12) *  8);}
  .col-xl-9  {flex-basis: calc((100% / 12) *  9); max-width: calc((100% / 12) *  9);}
  .col-xl-10 {flex-basis: calc((100% / 12) * 10); max-width: calc((100% / 12) * 10);}
  .col-xl-11 {flex-basis: calc((100% / 12) * 11); max-width: calc((100% / 12) * 11);}
  .col-xl-12 {flex-basis: calc((100% / 12) * 12); max-width: calc((100% / 12) * 12);}

}

@media only screen and (min-width: 1800px) {

  .col-xxl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xxl-1  {flex-basis: calc((100% / 12) *  1); max-width: calc((100% / 12) *  1);}
  .col-xxl-2  {flex-basis: calc((100% / 12) *  2); max-width: calc((100% / 12) *  2);}
  .col-xxl-3  {flex-basis: calc((100% / 12) *  3); max-width: calc((100% / 12) *  3);}
  .col-xxl-4  {flex-basis: calc((100% / 12) *  4); max-width: calc((100% / 12) *  4);}
  .col-xxl-5  {flex-basis: calc((100% / 12) *  5); max-width: calc((100% / 12) *  5);}
  .col-xxl-6  {flex-basis: calc((100% / 12) *  6); max-width: calc((100% / 12) *  6);}
  .col-xxl-7  {flex-basis: calc((100% / 12) *  7); max-width: calc((100% / 12) *  7);}
  .col-xxl-8  {flex-basis: calc((100% / 12) *  8); max-width: calc((100% / 12) *  8);}
  .col-xxl-9  {flex-basis: calc((100% / 12) *  9); max-width: calc((100% / 12) *  9);}
  .col-xxl-10 {flex-basis: calc((100% / 12) * 10); max-width: calc((100% / 12) * 10);}
  .col-xxl-11 {flex-basis: calc((100% / 12) * 11); max-width: calc((100% / 12) * 11);}
  .col-xxl-12 {flex-basis: calc((100% / 12) * 12); max-width: calc((100% / 12) * 12);}

}