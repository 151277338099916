.container-mint {
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(90deg, #00285a 0%, rgba(0, 40, 90, 0.2) 100%), url(https://thenemesis.io/wp-content/uploads/2023/02/GALAXY.webp) !important;
    background-size: cover;
    /* background-attachment: fixed; */
    color: #fff;
    overflow-y: auto;
}

#reservation-lists {
    border-collapse: collapse;
    display: flex;
}

table {
    margin: 0 auto !important;
    width: 100% !important;
}

#reservation-lists td {
    border-bottom: 1px solid #fff;
    padding: 10px;
}

.wrap-summary {
    top: 20px;
}

#check-all {
    position: absolute;
    right: 29px;
    font-size: 30px;
}

.mint.glass-card {
    background: rgba(255, 255, 255, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.4px);
    -webkit-backdrop-filter: blur(6.4px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    padding: 20px;
}

.container-mint button {
    color: #FFFFFF !important;
    font-family: Alphakind;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    background-color: #1077ef;
    text-decoration: none;
    font-size: initial;
    padding: 20px 40px;
    border-radius: 50px;
    border: 2px solid #fff;
}

.container-mint button:disabled {
    background: #dddddd;
}

.p1-ground {
    background: #2da835;
}

.p1-filler {
    background: #264fd6;
}

.land-plot {
    width: 200px !important;
    height: 200px !important;
}

.land-plot td {
    border: none !important;
}

.companion-preview {
    width: 102px;
    height: 102px;
}

.divider {
    padding-top: 12px;
    border-top: 1px solid white;
}

/********CHECKBOX*********/

.checkbox-wrapper-40 {
    --borderColor: #48c;
    --borderWidth: .125em;
}

.checkbox-wrapper-40 label {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.checkbox-wrapper-40 input[type=checkbox] {
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    background: #fff;
    font-size: 1.8em;
    border-radius: 0.125em;
    display: inline-block;
    border: var(--borderWidth) solid var(--borderColor);
    width: 0.8em;
    height: 0.8em;
    position: relative;
}

.checkbox-wrapper-40 input[type=checkbox]:before,
.checkbox-wrapper-40 input[type=checkbox]:after {
    content: "";
    position: absolute;
    background: var(--borderColor);
    width: calc(var(--borderWidth) * 3);
    height: var(--borderWidth);
    top: 50%;
    left: 5%;
    transform-origin: left center;
}

.checkbox-wrapper-40 input[type=checkbox]:before {
    transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
    transition: transform 50ms ease-in 50ms;
}

.checkbox-wrapper-40 input[type=checkbox]:after {
    width: calc(var(--borderWidth) * 5);
    transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
    transform-origin: left center;
    transition: transform 50ms ease-in;
}

.checkbox-wrapper-40 input[type=checkbox]:checked:before {
    transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(1);
    transition: transform 50ms ease-in;
}

.checkbox-wrapper-40 input[type=checkbox]:checked:after {
    width: calc(var(--borderWidth) * 5);
    transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
    transition: transform 50ms ease-out 50ms;
}

.checkbox-wrapper-40 input[type=checkbox]:focus {
    outline: calc(var(--borderWidth) / 2) dotted rgba(0, 0, 0, 0.25);
}

/********CHECKBOX*********/


/********LOADER*********/
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

/********LOADER*********/


/* sx */
@media only screen and (min-width: 576px) {
    #wrap-list {
        left: 35%;
    }

}

@media only screen and (min-width: 768px) {
    #wrap-list {
        left: 45%;
        min-width: 45%;
    }

    .checkbox-wrapper-40 input[type=checkbox] {
        width: 1em;
        height: 1em;
    }

    .checkbox-wrapper-40 input[type=checkbox]:before,
    .checkbox-wrapper-40 input[type=checkbox]:after {
        left: 10%;
    }

}

@media only screen and (min-width: 1124px) {
    .wrap-summary {
        position: fixed;
        max-width: calc((100% / 12) * 3.5)
    }

    /* #wrap-list {
        position: absolute;
    } */
}

@media only screen and (min-width: 1280px) {
    /* xl */
}

@media only screen and (min-width: 1800px) {
    /* xxl */
}